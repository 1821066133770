<template>
  <div class="user-app-list-page w-100">
    <basic-subheader>
      <template v-slot:filters>
        <b-row>
          <b-col>
            <basic-input
              label="Tên nhóm"
              placeholder="Nhập tên nhóm"
              name="code"
              class="coach-filter"
              :value.sync="filter.name"
              :debounce="1000"
              @v-modal:update="searchDebounce"
            />
          </b-col>
          <b-col>
            <basic-select
              label="Coach"
              placeholder="--- Chọn coach ---"
              name="coach"
              class="coach-filter"
              :allowEmpty="true"
              :options="coaches"
              :value.sync="filter.healthCoach"
              value-label="text"
              track-by="value"
              @update:value="searchWithCoach"
            />
          </b-col>
        </b-row>
      </template>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
          ref="dropdownFormFilter"
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-input
                    label="Mã nhóm"
                    placeholder="Nhập mã nhóm"
                    name="code"
                    :value.sync="filter.code"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên nhóm"
                    placeholder="Nhập tên nhóm"
                    name="name"
                    :value.sync="filter.name"
                  ></basic-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Coach"
                    placeholder="--- Chọn coach ---"
                    name="coach"
                    :solid="false"
                    :allowEmpty="true"
                    :value.sync="filter.healthCoach"
                    :options="coaches"
                    value-label="text"
                    track-by="value"
                    @update:value="setHealthCoach"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Status"
                    placeholder="--- Chọn status ---"
                    name="status"
                    :options="statuses"
                    :allowEmpty="true"
                    :value.sync="filter.status"
                    track-by="value"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.prevent="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWriteAllPermission || isWriteOwnerPermission"
          class="btn btn-success"
          type="button"
          @click.stop="createCoach"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm mới
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <template-table
          :column="column"
          :data="coachGroups"
          :paging="paging"
          :tableAction="false"
          :selectAction="false"
          :searchAction="false"
          boundary="window"
          @sortBy="sortRequest"
        >
          <template v-slot:body="{ item }">
            <td>
              <div class="grap-info">
                <avatar
                  size="40px"
                  :text="item.name"
                  :src="item.image.url"
                  :rounded="true"
                />
                <div class="d-flex flex-column">
                  <p class="mb-0 module-list-page__body__updater-name">
                    {{ item.name }}
                  </p>
                  <p class="mt-2 mb-0 module-list-page__body__updater-code">
                    {{ item.code }}
                  </p>
                </div>
              </div>
            </td>
            <td style="width: 20px">
              <action-dropdown
                :value="item"
                :show_copy="false"
                :show_view="false"
                :show_delete="
                  !showActiveBtn(item) &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
                :show_edit="false"
                :boundary="coachGroups.length > 2 ? 'scrollParent' : 'window'"
                @delete="showPopupChangeStatus(item.id, 0)"
              >
                <b-dropdown-text
                  tag="div"
                  class="navi-item cursor-pointer"
                  @click="viewGroup(item)"
                >
                  <a class="navi-link text-primary">
                    <span class="menu-icon svg-icon svg-icon-sm">
                      <inline-svg
                        class="svg-icon"
                        src="/media/svg/icons/Neolex/Basic/eye.svg"
                      />
                    </span>
                    <span class="navi-text text-primary"
                      >Xem chi tiết nhóm</span
                    >
                  </a>
                </b-dropdown-text>
                <b-dropdown-divider
                  v-if="isWriteAllPermission || isWriteOwnerPermission"
                ></b-dropdown-divider>
                <template v-if="isWriteAllPermission || isWriteOwnerPermission">
                  <b-dropdown-text
                    tag="div"
                    class="navi-item cursor-pointer"
                    @click="editGroup(item)"
                  >
                    <a class="navi-link text-primary">
                      <span class="menu-icon svg-icon svg-icon-sm">
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                        />
                      </span>
                      <span class="navi-text text-primary">Chỉnh sửa nhóm</span>
                    </a>
                  </b-dropdown-text>
                  <b-dropdown-divider />
                  <template v-if="showActiveBtn(item)">
                    <b-dropdown-text tag="div" class="navi-item cursor-pointer">
                      <a
                        class="navi-link text-primary"
                        @click.stop="showPopupChangeStatus(item.id, 1)"
                      >
                        <span class="menu-icon svg-icon svg-icon-sm">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/power.svg"
                          />
                        </span>
                        <span class="navi-text text-primary">Active</span>
                      </a>
                    </b-dropdown-text>
                  </template>
                </template>
              </action-dropdown>
            </td>
            <td>
              <div class="grap-info">
                <avatar
                  size="40px"
                  :text="item.healthCoachName"
                  :src="item.healthCoachImage.url"
                  :rounded="true"
                />
                <div class="d-flex flex-column">
                  <p class="mb-0 module-list-page__body__updater-name">
                    {{ item.healthCoachName }}
                  </p>
                  <p class="mt-2 mb-0 module-list-page__body__updater-code">
                    {{ item.healthCoachCode }}
                  </p>
                </div>
              </div>
            </td>
            <td class="text-center">{{ item.patientPackageActive }}</td>
            <td class="text-center">{{ item.patientPackageExpired }}</td>
            <td class="text-center">
              {{ formatDate(item.createDatetime) }}
            </td>
            <td class="text-center">
              <div class="status" :class="getStatus(item.status)">
                {{ getStatus(item.status) }}
              </div>
            </td>
          </template>
        </template-table>
      </b-overlay>
    </Wrapper>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import { CHANGE_STATUS } from '@/core/services/store/coach.module';
const { mapActions } = createNamespacedHelpers('coach');
export default {
  name: 'CoachList',
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      coachGroups: [],
      coaches: [],
      filter: {
        name: null,
        healthCoach: null,
        code: null,
        status: null,
        orderBy: null,
      },
      statuses: [
        {
          name: 'Active',
          value: 1,
        },
        {
          name: 'Inactive',
          value: 0,
        },
      ],
      sort: {
        by: null,
        order: null,
      },
    };
  },
  computed: {
    column() {
      return [
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '20px',
          },
        },
        {
          key: 'healthCoachName',
          label: 'Coach',
          sortable: false,
        },
        {
          key: 'patientActive',
          label: 'Số lượng đang tham gia',
          sortable: true,
          class: 'text-center patient-active',
          style: {
            width: '15%',
          },
        },
        {
          key: 'patientExpired',
          label: 'Số lượng đã kết thúc',
          sortable: true,
          class: 'text-center patient-expire',
          style: {
            width: '14%',
          },
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
          style: {
            width: '115px',
          },
          class: 'text-center',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          style: {
            width: '5%',
          },
        },
      ];
    },
    searchParams() {
      return {
        name: this.filter.name || null,
        code: this.filter.code || null,
        orderByExtend: this.sort.by
          ? `${this.sort.by} ${this.sort.order}`
          : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        accountId: this.filter.healthCoach?.value || null,
        status: this.filter.status,
      };
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },
  watch: {
    'paging.page': function () {
      this.getCoachGroups();
    },
    'paging.pageSize': function () {
      this.getCoachGroups();
    },
  },
  async created() {
    await Promise.all([this.getCoaches(), this.getCoachGroups()]);
  },
  methods: {
    ...mapActions({ CHANGE_STATUS }),
    resetRequest() {
      this.filter = {
        name: null,
        healthCoach: null,
        code: null,
        status: null,
        orderBy: null,
      };
      this.resetPaging();
      document.body.click();
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.getCoachGroups();
    },
    searchRequest() {
      this.resetPaging();
      document.body.click();
    },
    createCoach() {
      this.$router.push({
        name: 'coach_create',
      });
    },
    async getCoachGroups() {
      this.$store.commit('context/setLoading', true);
      try {
        const params = this.searchParams;
        const { data, meta, error } = await this.$api.get('TrainingGroup', {
          params,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.paging.total = meta.total;
        this.coachGroups = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async getCoaches() {
      try {
        const { data, meta } = await this.$api.get(
          'TrainingGroup/PrepareSearchFormItem',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: data.message,
          });
        }
        this.coaches = data.healthCoaches;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
        });
      }
    },
    searchDebounce(value) {
      this.filter.name = value;
      this.resetPaging();
    },
    resetPaging() {
      this.paging.page = 1;
      this.getCoachGroups();
    },
    getStatus(status) {
      if (status === 0) return 'inactive';
      if (status === 1) return 'active';
    },
    showActiveBtn(item) {
      if (item.status === 1) {
        return false;
      }
      return true;
    },
    editGroup(value) {
      this.$router.push({
        name: 'coach_edit',
        params: {
          id: value.id,
        },
      });
    },
    viewGroup(value) {
      this.$router.push({
        name: 'coach_detail',
        params: {
          id: value.id,
        },
      });
    },
    async searchWithCoach(value) {
      await this.setHealthCoach(value);
      this.getCoachGroups();
    },
    setHealthCoach(value) {
      if (value) {
        this.filter.healthCoach = this.coaches.find((el) => el.value === value);
      } else {
        this.filter.healthCoach = null;
      }
    },
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
    showPopupChangeStatus(id, state) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          state ? 'Active' : 'Inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(id, state);
          }
        }.bind(this),
      );
    },
    async changeStatus(id, state) {
      this.$store.commit('context/setLoading', true);
      const params = {
        id,
        state,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.getCoachGroups();
      } else {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
      this.$store.commit('context/setLoading', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.mw-400 {
  min-width: 400px;
}
.status {
  padding: 6px 24px;
  border-radius: 20px;
  color: #fff;
  text-transform: capitalize;
  width: 96px;
  text-align: center;

  &.active {
    background: #21a567;
  }

  &.inactive {
    background: #ff5756;
  }

  &.draft {
    background: #888c9f;
  }
}

.grap-info {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 16px;
  align-items: center;
}
</style>

<style lang="scss">
.coach-filter {
  min-width: 250px;
  .multiselect {
    min-width: 250px;
  }
}

.patient-active,
.patient-expire {
  .sorting {
    justify-content: center;
    padding-right: 0;
  }
}
</style>
